
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Tables
@import "tables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.menu-bar{
	margin-top: 20px;
	margin-bottom: 40px;
	padding: auto;

}

.navbarbuttons {
  margin-bottom: 40px;
}

.v-align-cell {
  vertical-align: middle;
}

.description {
	width: 100%;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  //background-color: #fff;
}

.autocomplete-suggestions { border: 1px solid #999; background: #FFF; cursor: default; overflow: auto; -webkit-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64); -moz-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64); box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64); }
.autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }

#bday, #bmonth, #byear {width: 27%; float: left;}
#labelday{width: 4%; float: left; margin-left: 1%;margin-top: 6px;}
#labelmonth{width: 4%; float: left; margin-left: 2%; margin-top: 6px;}
#labelyear{width: 5%; float: left; margin-left: 2%; margin-top: 6px;}

.cbpay {
  width: 14px;
  height: 14px;
  vertical-align:middle;
}

