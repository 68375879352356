.actions form{ 
	display: inline-block;
}

.action-delete{
	padding: 0;
}
.actions button{
	padding: 0;
}

.actionsFactures {
  text-align:right; 
  padding-left:0px; 
  padding-right:0px;
}
